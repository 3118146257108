section#donation-success {
    background-color: #FFFFFF;
    min-height: 860px;
    .success-wrapper {
        width: 100%;
        height: 860px;
        @media only screen and (max-width: 1024px) {
            padding: 20px 0;
        }
        .success-message-wrap {
            width: 100%;
            max-width: 456px;
            @media only screen and (max-width: 1024px) {
                width: 100% !important;
                padding:  0 20px;
                
            }
            .title-check {
                margin-bottom: 100px;
                .check-icon {
                    margin-bottom: 24px;
                }
                .success-title {
                    h2 {
                        text-align: center !important;
                        font-weight: 600;
                    }
                }
            }
    
            .thank-you-letter {
                .letter {
                    p.dear {
                        font-size: 18px;
                        padding-bottom: 32px;
                        text-align: center ;
                        font-weight: 300;
                    }
                    p.note {
                        font-size: 18px;
                        line-height: 32px;
                        text-align: center;
                        font-weight: 300;
                    }
                }
            }
        }
    }
   
}