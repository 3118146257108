.accent-section-title {
    padding: 2.5rem 0 1.25rem 0;
    .bar {
        width: 40px;
        height: 4px;
        margin-bottom: 1.5rem;
        background-color: $vocih-accent;
    }
    h5 {
        font-size: 1.5rem;
    }
}