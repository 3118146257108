.form-row {
    width: 100%;
    justify-content: space-between;
    margin-left: 0 !important;
    margin-right: 0 !important;
    margin-bottom: 1rem;
    padding-left: 0 !important;
    padding-right: 0 !important;
    &:last-child {
        margin-bottom: 0;
    }

    @media only screen and (max-width: 700px) {
        flex-direction: column !important;
    }
}
.form-col {
    width: calc(50% - 12px);
    @media only screen and (max-width: 700px) {
        width: 100% !important;       
    }
}

.form-col-full {
    width: 100%;
}

label {
    font-size: 13px;
    line-height: 14px;
    font-weight: 500;
    display: block;
    font-family: $font;
}

input, select {
    height: 40px;
    box-sizing: border-box;
    padding-left: 0.5rem;
    border: 1px solid #E3E3E3;
    font-weight: 300;
    display: block;
    border: 1px solid $input-outline;
    &:focus { 
        border: 1px solid $vocih-accent;
        outline: none;
    }
    &.error { 
        border: 1px solid #D84A4A;
    }
}

textarea {
    border: 1px solid $input-outline;
    &:focus {
        border: 1px solid $vocih-accent;
        outline: none; 
    }
}

button {
    appearance: none;
    outline: none;
    border: none;
    border-radius: 3px;
}

