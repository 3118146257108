
button, .button {
    outline: none;
    border: 0;
    font-size: 1rem;
    font-family: $font;
    line-height: 1.125rem;
    box-sizing: border-box;
    font-weight: 600;
    display: block;
    text-align: center;
    &:hover {
        text-decoration: none;
    }
    &:focus {
        outline: none !important;
    }
    &.primary {
        background-color: $vocih-accent;
        color: #FFF;
        &:hover {
            background-color: #9965FF;
        }
        &:focus {
            background-color: #771AEA;
        }
        &.disabled-btn {
            background-color: #e5e3e9;
            cursor: default;
            color: #d3cfd8 !important;
        }
    }
    &.primary-reverse {
        background-color: #FFF;
        color: $vocih-accent;
        &:hover {}
    }

    &.outline
    {
        background-color: none !important;
        border: 1px solid $vocih-accent;
        color: $vocih-accent;
        &:hover {
            border-color: $vocih-accent;
            background-color: $vocih-accent;
            color: #FFFFFF;
        }
    }

    &.outline-reverse {
        background-color: none !important;
        border: 1px solid #FFFFFF;
        color: #FFFFFF;
        &:hover {
            border-color: #FFF;
            background-color: #FFF;
            color: $vocih-accent;
        }
    }

    &.small {
        padding: 12px 16px;
        font-size: 14px;
        line-height: 14px;
        height: 40px;
        min-width: 120px;
    }
    &.medium {
        padding: 14px 24px;
        font-size: 16px;
        line-height: 16px;
        height: 48px;
        font-weight: normal;
    }
    &.large {
        padding: 20px 32px;
        font-size: 20px;
        line-height: 20px;
        height: 56px;
        font-weight: normal;
    }


    

   
}




.switch {
    background-color: #FFF;
    border: 1px solid $input-outline;
    font-size: 1rem;
    color: #313132;
    font-weight: 400;
    padding: 0 !important;
    height: 44px;
    font-family: $font;
    &:focus {
        outline: none;
    }
    &.active {
        background-color: $vocih-accent !important;
        font-weight: 600;
        color: #FFFFFF;
        border: 1px solid $vocih-accent;
        &:hover {
            background-color: $vocih-accent !important;
            font-weight: 600;
            color: #FFFFFF;
            border: 1px solid $vocih-accent;
        }
    }
    &:hover {
        background-color: rgba($color: $vocih-accent, $alpha: .08) !important;
        border: 2px solid $vocih-accent;
        font-weight: 600;
        color: $vocih-accent;
        border-color: $vocih-accent;
    }
}