section#donation-checkout {
    // background-color: #f7f6f8c5;
     background-color: #FFF;
     min-height: 860px;

    .checkout-wrapper {
        width: 100%;
        height: 860px;
        @media only screen and (max-width: 1024px) {
            padding: 20px !important;
            
        }
        .checkout-wrap {
            width: 100%;
            max-width: 456px;
            @media only screen and (max-width: 1024px) {
                width: 100% !important;
                
            }
            .checkout-notification-wrap {
                width: 100%;
                padding: 48px 0;
                .paypal-processing-error {
                    box-sizing: border-box;
                    height: 60px;
                    width: 456px;
                    
                    background-color: #fff;
                    border: 1px solid #da34343a;
                    border-radius: 3px;
                    padding: 0 20px;
                    @media only screen and (max-width: 1024px) {
                        width: 100% !important;
                        
                    }
                    .error-icon {
                        width: 32px;
                        height: 60px;
                    }
                    .paypal-error-text {
                        width: calc(100% - 32px);
                        padding-left: 12px;
                        span {
                            font-size: 17px !important;
                            color: #d41818;
                        }
                    }
                }
                .paypal-processing-success {
                    box-sizing: border-box;
                    height: 60px;
                width: 456px;
                background-color: #FFFFFF;
                border: 1px solid #69893246;
                border-radius: 3px;
                padding: 0 20px;
                @media only screen and (max-width: 1024px) {
                    width: 100% !important;
                    
                }
                    .success-icon {
                        width: 32px;
                        height: 60px;
                    }
                    .paypal-success-text {
                        width: calc(100% - 32px);
                        padding-left: 12px;
                        span {
                            font-size: 17px !important;
                            color: #04883b;
                        }
                    }
                }
            }
            .checkout-summary-box {
                
                .checkout-title {
                    margin-bottom: 24px;
                    h3 {
                        font-size: 24px;
                        font-weight: 600;
                    }
                }
                .content-box {
                    background-color: #FFFFFF;
                    padding: 24px;
                    border: 1px solid #E3E3E3;
                    .checkout-donation-category {
                        margin-bottom: 24px;
                        .title {
                            margin-bottom: 8px;
                            span {
                                font-size: 18px !important;
                                line-height: 24px;
                                color: #4b4b4d;
                            }
                        }
                        .frequency {
                            background-color: #F2F2F3;
                            display: block;
                            font-size: 11px;
                            width: 83px;
                            color: #959595;
                            text-transform: uppercase;
                            letter-spacing: 1px;
                            padding: 2px 10px;
                        }
                    }
                    .giving-box {
                        
                        .figures {
                            .giving-figure, .process-fee-figure, .total-figure {
                                
                                padding: 24px 0;
                                border-top: 1px solid #E3E3E3;
                                span {
                                    display: block;
                                    &.caption {
                                        color: #716F72;
                                        font-size: 15px !important;
                                    }
                                    &.figure-number {
                                        font-weight: 600;
                                        font-size: 15px;
                                    }
                                    &.total-number {
                                        font-size: 34px !important;
                                        font-weight: 600;
                                    }
                                }
                                
                                
                            }
                        
                        }
                        .paypal-checkout-button {
                            width: 100%;
                        }
                    }
                }
            }

            
        }
    }
}