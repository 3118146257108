



.my-masonry-grid {
    display: -webkit-box; /* Not needed if autoprefixing */
    display: -ms-flexbox; /* Not needed if autoprefixing */
    display: flex;
    margin-left: -30px; /* gutter size offset */
    width: auto;
  }
  .my-masonry-grid_column {
    padding-left: 30px; /* gutter size */
    background-clip: padding-box;
  }
   
  /* Style your items */
  .my-masonry-grid_column { /* change div to reference your elements you put in <Masonry> */
    margin-bottom: 30px;
    .item {
          
            margin-bottom: 30px;
            border-radius: 3px;
            box-sizing: border-box;
            cursor: pointer;
            &:hover {
                .item-img {
                   transform: scale(1.2);
                }
                .tint-overlay-content {
                    opacity: 1 !important;
                }
                
            }
            
            .content {
                border-radius: 5px;
                overflow: hidden;
                width: 100%;
                height: 100%;
                position: relative;
                
                .item-img {
                    width: 100%;
                    height: 100%;
                    position: relative;
                    overflow: hidden;
                    background-size: cover;
                    background-position: center center;
                    transition: all ease .2s;
                }
                .tint-overlay-content {
                    transition: all ease .1s;
                    opacity: 0;
                    position: absolute;
                    top: 0;
                    z-index: 100;
                    width: 100%;
                    height: 100%;
                    background-color: rgba($color: #00234D, $alpha: .80);
                    box-sizing: border-box;
                    padding: 24px;
                    display: flex;
                    align-items: flex-end;
                    .text {
                        p {
                            font-size: 1rem !important;
                            color: rgba(255, 255, 255, 0.66);
                        }
                    }
                }
              
        }
    }
}