
section#call-to-action {
    background-color: $vocih-accent;
    padding: $spc-10;
    @media only screen and (max-width: 1024px) {
        padding: 64px 0;      
       }
        .cta-content {
            width: 100%;
            @media only screen and (max-width: 1024px) {
                flex-direction: column !important;  
               }
            .cta-section-title {
                text-align: left;
                width: 65%;
                @media only screen and (max-width: 1024px) {
                    width: 100% !important;  
                    margin-bottom: 24px;     
                   }
                h3 {
                    font-family: $font;
                    font-size: 2.125rem !important;
                    line-height: 3rem !important;
                    color: #FFFFFF;
                }
            }

            .CTA-button-wrap {
                width: 45%;
                @media only screen and (max-width: 1024px) {
                    width: 100% !important;  
                    justify-content: start !important;     
                   }
                .donate-btn {
                    width: 189px;
                }
            }

            
      
    }

}
    