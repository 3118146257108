section#subscribe {
    background: #000;
    padding: $spc-10;
    background-image: url('../../images/subscribe-bg.png');
    @media only screen and (max-width: 1024px) {
        padding: 124px 0;
    }
    .subscribe-wrap {
        width: 100%;
        padding: 2.5rem 0;
        @media only screen and (max-width: 1024px) {
        padding: 0 !important;
        }
        .subscribe-content {
            
            width: 70%;
            margin: 0 auto;
            box-sizing: border-box;
            @media only screen and (max-width: 1024px) {
                width: 100% !important;
            }
            .text {
                width: 100%;
                text-align: center;
                margin-bottom: 4rem;
                
                h3 { 
                    color: #FFF;
                    font-size: 2.75rem;
                    font-weight: 500;
                    color: #FFFFFF;
                    margin-bottom: 1.5rem;
                }
                p {
                    color: rgb(149, 149, 149);
                    font-weight: 300;
                    font-size: 1.125rem;
                }
            }
            .form {
                width: 90%;
                margin: 0 auto;
                margin-bottom: 4rem;

                .status-messages {
                    padding-bottom: 10px;
                    p.email-error {
                            color: #f64444 !important;
                            font-size: 13px !important;
                    }
                    .success {
                        .icon {
                            margin-right: 12px;
                        }
                        .msg {
                            p {
                            color: #05CF59;
                            font-weight: 300;
                            font-size: 13px !important;
                            }
                      
                        }
                    }
                }
              
                
                .form-content {
                    width: 100%;
                    @media only screen and (max-width: 1024px) {
                        flex-direction: column !important;
                    }
                    .input-col {
                        box-sizing: border-box;
                        width: 100%;

                        input {
                            background: none;
                            border: 1px solid #6D6C6E !important;
                            height: 48px;
                            width: 100%;
                            color: #FFF;
                            font-weight: 300;
                            font-size: 16px;
                            @media only screen and (max-width: 1024px) {
                                width: 100% !important;
                                margin-bottom: 1.5rem;
                            }
                        }
                    }
                    .subscribe {
                        button.subscribe-button {
                            outline: none;
                            border: none;
                            height: 48px;
                            width: 160px;
                            box-sizing: border-box;
                            font-size: 16px !important;
                            line-height: 16px !important;
                            padding: 0 !important;
                            @media only screen and (max-width: 1024px) {
                                width: 100% !important;
                            }
                        }
                    }
                }
                .hp-input-col {
                    input {
                        height: 16px;
                        visibility: hidden !important;
                    }
                }
            }

            .respect {
                p {
                    text-align: center;
                    color: rgb(149, 149, 149);
                    font-weight: 300;
                    font-size: 13px;
                }
            }
        }
    } 
}