section#how_we_do_it {
    padding: $spc-12 0;
    background-color: $grey-section-bg;
    @media only screen and (max-width: 900px) {
        padding-top: 124px;
        padding-bottom: 124px;
    }
    .hwdi-content {
        width: 100%;
        
        @media only screen and (max-width: 1024px) {
            flex-direction: column !important;
        }
        .hwdi-content-title {
            width: 464px;
            @media only screen and (max-width: 1024px) {
                width: 100% !important;
                margin-bottom: 24px;
            }
            h3 {
                font-size: 2.125rem;
                font-weight: 500;
                line-height: 3.25rem;
            }
        }
        .hwdi-content-copy {
            width: 519px;
            @media only screen and (max-width: 1024px) {
                width: 100% !important;
            }
            p {
                font-size: 1.06rem;
                line-height: 2rem;
                font-weight:300;
                span.emph {
                    font-weight: 600;
                }
            }
        }
    }
}






section#vocih-pillars {
    background-color: #FFFFFF;
    width: 100%;
    padding: $spc-18 0;
    background-image: url('../../images/wavy-pattern.png');
    background-position: top center;
    background-repeat: no-repeat;
    background-size: contain;
    @media only screen and (max-width: 1024px) {
        padding: 124px 0px;
    }
    .vocih-pillar-wrap {
        width: 100%;
        box-sizing: border-box;

        .vocih-pillars-title {
            width: 657px;
            margin-bottom: $spc-16;
            @media only screen and (max-width: 1024px) {
                margin-bottom: 80px;
            }
            @media only screen and (max-width: 960px) {
                width: 100% !important;
            }
            p.overline {
                color: $vocih-accent;
                text-transform: uppercase;
                letter-spacing: 1px;
                display: block;
                padding-bottom: 20px;
            }
            h2 {
                font-weight: 500;
                font-size: 64px;
                line-height: 80px;
                color: $vocih-primary;
                margin-bottom: 1.5rem;
                @media only screen and (max-width: 760px) {
                   font-size: 56px !important;
                   line-height: 72px;
                }
            }
            p.subtext {
                font-size: 18px;
                font-weight: 300 !important;
            }
        }
        .pillars {
            width: 100%;
            @media only screen and (max-width: 1024px) {
                width: 100% !important;
                flex-direction: column !important;
            }
            .pillar {
                width: 360px;
                @media only screen and (max-width: 1024px) {
                   width: 100% !important;
                   margin-bottom: 40px !important;
                   &:last-child {
                       margin-bottom: 0 !important;
                   }
                }
                &:last-child { margin-bottom: 0;}
                .pillar-content {
                    .pillar-title {
                        width: 100;
                        margin-bottom: 24px;
                        h3 { 
                            font-size: 34px !important;
                            line-height: 48px;
                            font-weight: 500 !important;
                            
                        }
                    }
                    .text-cta-wrap {
                        
                        .pillar-copy {
                            
                            p {
                                font-weight: 300;
                                font-size: 16px;
                                line-height: 28px;
                            }
                        }
                      

                    }
                }
                
            } 
        }
           
        
    }
}

section#mission_vision {
    background-color: $grey-section-bg;
    padding: $spc-18;
    box-sizing: border-box;
    @media only screen and (max-width: 1024px) {
        padding: 124px 0px;
    }
  
    .mv_copy{
        p {

        }
    }
    .mv_content_wrap {
        @media screen and (max-width: 992px) {
           flex-direction: column !important;
        }
       .mv_content {
        box-sizing: border-box;
        width: 40%;
        padding-left: 0;
        padding-right: 0px;
        @media screen and (max-width: 992px) {
            width: 100% !important;
            display: flex;
            flex-direction: column !important;
            justify-content: space-around;
         }
            .mission-vision {
                padding-right: $spc-10;
                margin-bottom: $spc-16;
                @media only screen and (max-width: 1024px) {
                    padding-right: 0px;
                    margin-bottom: $spc-10;
                }
                .title {
                    margin-bottom: 2rem;
                    h3 {
                         color: #FFF;
                         font-size: 4rem;
                         font-weight: 500;
                         color: $vocih-primary;
                         @media only screen and (max-width: 1024px) {
                            font-size: 56px !important;
                        }
                    }
                }
                .copy {
                    p {
                        color: $vocih-primary;
                        font-size: 16px !important;
                        line-height: 28px;
                        font-weight: 300;
                    }
                }

                &:last-child { margin-bottom: 0 !important;}
            }
            
        }
        .haiti_map_wrap {
           box-sizing: border-box;
            width: 60%;
            height: 100%;
            position: relative;
            padding: 0;
            @media only screen and (max-width: 1024px) {
                width: 100%;
            }
            @media only screen and (max-width: 578px) {
                display: none !important;
            }
            .map-image {
                position: relative;
                top: 0;
                right: 0 ;
                
            }

            .hq-pin-card {
                border-radius: 5px;
                background: #FFF;
                width: 262px;
                padding: 1.5rem;
                box-sizing: border-box;
                position: absolute;
                top: 160px;
                right: 140px;
                box-shadow: 0px 4px 40px 20px rgba($color: $vocih-primary, $alpha: 0.10);
           
                .overline {
                    margin-bottom: 8px;
                    h6 {
                        text-align: left !important;
                        font-size: 13px;
                        line-height: 14px !important;
                        margin: 0 !important;
                        
                    }
                }
                .hq {
                    .hq-city {
                        display: block;
                        font-size: 1.25rem !important;
                        font-weight: 600;
                        margin-bottom: 8px;
                        color: #706B7A;
                    }
                    .hq-where {
                        display: block;
                        color: #8A9BAC;
                        font-size: 14px !important;
                        line-height: 14px;
                        font-weight: 300;
                        color: #939297;
                    }
                }
                
            }
        
            .pins{
                position: relative;
                height: 432px;
                .tooltip-content-wrapper {
                    position: relative;
                    left: 260px;
                    top: 60px;
                    opacity: 1;
                    z-index: 1000
                }
                
                .pin {
                    border-radius: 100%;
                    background-color: rgba($color: $vocih-accent, $alpha: 0.2);
                    cursor: pointer;
                    animation-name: map_pins;
                    animation-duration: 1s;
                    animation-timing-function: ease;
                    animation-iteration-count: infinite;
                    transition: all ease-in-out .2s;
                    &:hover { background-color: #FFF;}
                    &.pin1 { 
                        width: 80px;
                        height: 80px;
                        position: absolute;
                        top: 132px;
                        right: 100px;
                        &:hover { 
                            transform: scale(1.4);
                        }
                    }
                    
                    
                        .pin-inner {
                            background-color: $vocih-accent;
                            width: 40%;
                            height: 40%;
                            border-radius: 100%; 
                        }
                    
                }

            }
            
                
        }
  
    }

  
}


section#sponsor-child {
    background-color: $vocih-primary;
    background-attachment: fixed;
    background-image: url('../../images/sac-bg.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;
    padding: $spc-18;
    height: 800px;
    @media only screen and (max-width: 1024px) {
        width: 100%;
        padding: 0px 0;
        height: 640px;
    }
    .sac-container {
        @media screen and (max-width: 1024px) {
            flex-direction: column  !important;
            padding-top: 80px;
            padding-bottom: 80px;
        }
        .content {
            width: 538px;
            @media screen and (max-width: 1024px) {
                width: 100%;
            }
            .main-callout {
                margin-bottom: 2rem;
                h3 {
                    color: #FFF;
                    font-family: $font;
                    font-size: 3.5rem;
                    line-height: 72px;
                    font-weight: 500;
                    @media only screen and (max-width: 1024px) {
                        font-size: 3rem !important;
                    }
                }
            }
            .sac-description {
                margin-bottom: 2.5rem;
                padding-right: 80px;
                p {
                    color: #DAEBFB;
                    font-size: 15px !important;
                    line-height: 28px;
                    font-weight: 300;
                }
            }
           
    
            .sac-donate-btn {
                width: 190px;
            }
        }
    
    }
   
}




section#gallery {
    padding: $spc-18 0 180px 0;
    background-color: #F7F6F8;
    @media only screen and (max-width: 1024px) {
        padding: 124px 0;
    }
    .gallery_content_top {
        width: 100%;
        margin-bottom: $spc-10;
        @media screen and (max-width: 992px) {
            width: 100%;
        }
        .title {
            margin-bottom: $spc-5;
            h3 {
                color: $vocih-primary;
                font-size: 4rem;
                font-weight: 500;
                @media only screen and (max-width: 1024px) {
                 font-size: 56px !important;       
                }
            }
        }
        

    }
    .masonry-images-content { 
        width:100%;
        margin: auto;
        
        @media screen and (max-width: 992px) {
            width: calc(100% - 40px);
        }
        .masonry-container {
            width: 100%;
            .owl-carousel {
                .owl-stage-outer {
                    overflow: visible !important; 
                }
                .owl-nav {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    button.owl-prev, button.owl-next {
                        width: 47px;
                        height: 47px;
                        border-radius: 100%;
                        transition: all ease 0.2s;
                        span {
                            color: rgba($color: $vocih-primary, $alpha: 0.3);
                            font-size: 32px !important;
                            transition: all ease 0.2s;
                            &:hover {
                                color: rgba($color: $vocih-primary, $alpha: 0.7);
                            }
                        
                        }
                        &:hover {
                            background-color: rgba($color: #FFFFFF, $alpha: .1) !important;
                        }
                    }
                }
            }
            .image-box {
                width: 100%;
                height: 100%;
                transition: all ease .2s;
                
            }
    
            
        }
    }
   
}


















