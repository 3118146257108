.nav-wrapper {
    width: calc(100% - 170px);
    height: 48px;
    .nav {
        width: 100%;
        height: 48px;
        .nav-links-wrap {
            margin: 0 2rem 0 auto;
            @media screen and (max-width: 1150px){
                display: none !important;
            }
            ul {
                margin: 0;
                padding: 0;
                height: 48px;
                li {
                    
                    margin: 0;
                    padding: 0;
                    list-style-type: none;
                    margin-right: 2rem;
                    height: 48px;
                    box-sizing: border-box;
                    &:last-child {
                        margin-right: 0;
                    }
                    a {
                        color: #CCCBCE;
                        font-size: 14px !important;
                        display: block;
                        width: 100%;
                        
                        box-sizing: border-box;
                        padding: 15px 4px 0px 4px;
                        font-weight: 300;
                        &:hover {
                            text-decoration: none;
                            border-bottom: 2px solid $vocih-accent;
                        }
                        &.page-active {
                            color: #FFFFFF;
                            font-weight: 500;
                            border-bottom: 2px solid $vocih-accent;
                        }
                    }
                    
                }
            }
        
        }

        .social-icons-wrap {
           margin: 0 4rem 0 2rem;
           @media screen and (max-width: 700px) {
            display: none !important;
            }
        }

        .donate-btn-wrap {
            margin: 0 0 0 1.5rem;
            .button {
                font-size: 18px !important;
                line-height: 18px !important;
            }
            @media screen and (max-width: 600px) {
                display: none !important;
            }
        }

        .mobile-menu-icon {
            display: none;
            @media only screen and (max-width: 700px) {
                display: block;
            }
        }
        
    }
}


