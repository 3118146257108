
section#footer {
    padding: $spc-16 0 2.5rem 0;
    background-color: #F6F6F6;
    @media only screen and (max-width: 1024px) {
        padding-top: 124px !important;
    }
    .footer-container {

        .f-content-wrap {
            @media only screen and (max-width: 1024px) {
                flex-direction: column !important;
            }
        
            .vocih-footer-logo-box, .footer-right-content {
                width: 50%;
                @media only screen and (max-width: 1024px) {
                    width: 100% !important;
                }
            }

            .vocih-footer-logo-box {
                @media only screen and (max-width: 1024px) {
                    margin-bottom: 56px !important;
                }
                .vocih-logo {
                    font-size: 2.25rem;
                    font-weight: 700;
                }
            
            }

            .footer-right-content {
                @media only screen and (max-width: 1024px) {
                    flex-direction: column !important;
                }
                .footer-menu {
                    @media only screen and (max-width: 1024px) {
                        margin-bottom: 40px !important;
                    }
                    h5{ 
                        padding-bottom: 1.25rem;
                    font-size: 1.4rem;
                    font-weight: 600;
                    }
                    ul {
                        list-style: none;
                        margin: 0 ;
                        padding: 0;
                        li {
                            font-size: 15px;
                            line-height: 2.0rem;
                            a {
                                color: #626164;
                                font-weight: 300;
                            }
                        }
                    }
                    &.links1 {}
                    &.links2 {}
                }

            }
        }
       

        .copyright-legal {
            padding-top: $spc-10;
            .copyrights {
                p {
                    text-align: right;
                    color: #626164;
                    font-size: 12px;
                    font-weight: 300;
                }
            } 
            
        }
    }

}



