
section#heading {
    background-color: #f2f2f3;
    padding: 32px 0;
    .content {
        .logo {}
    }
}

section#tabs {
    padding-top: 48px;
    @media only screen and (max-width: 375px) {
       display: none !important;
        
    }
    .tabs {
        margin: 0 auto;
        .tab {
            height: 28px;
            .number-step {
                width: 28px;
                height: 28px;
                border-radius: 100%;
                background: #A09FA1;
                color: #FFF;
                font-size: 13px;
                text-align: center;
                margin-right: 8px;
               
            }
            .tab-title {
                color: #A09FA1;
                font-size: 13px;
                @media only screen and (max-width: 475px) {
                    font-size: 11px !important;
                    
                }
            }
            &.active {
                color: #131117;
                font-weight: 500;
                .number-step {
                    background-color: #131117;
                    color: #FFFFFF;
                }
                .tab-title {
                    color: #131117;
                }
            }

            &.preference-billing {}

            &.line-bar {
                margin-left: 24px;
                margin-right: 24px;
                width: 50px;
                
                .bar {
                    height: 1px;
                    background-color: #B2B2B2;
                    width: 100%;
                }
                @media only screen and (max-width: 475px) {
                   display: none !important;
                    
                }
            }

            &.confirm-donate {}

            @media only screen and (max-width: 475px) {
                margin-right: 10px !important;
                
            }
            
        }
    }
}






section#donate-content-wrap {
    padding: $spc-18 0;
    @media only screen and (max-width: 1024px) {
        padding: 124px 0px;
    }
    .wrap {
        width: 904px;
        margin: 0 auto;
        @media only screen and (max-width: 1024px) {
            width: 100% !important;
            flex-direction: column;
        }
        .donate-section {
            width:478px;
            margin-right: 64px;
            @media only screen and (max-width: 1024px) {
                width: 100% !important;
                margin: 0 !important;
            }
            .donate-section-wrap {
                width: 100%;
                box-sizing: border-box;

                .donation-info-container {
                    h5.tab-title {
                        font-size: 24px;
                        margin-bottom: 2rem;
                        font-weight: 600;
                        
                    }
                    .error-wrap {
                        margin-bottom: 8px;
                        span.error {
                            font-size: 12px !important;
                            line-height: 12px !important;
                            color: #DA1818;
                            }
                    }
                
                    .donation-amount {
                        
                            .selectAmt {
                            width: 100%;
                                button.amt {
                                    box-sizing: border-box;
                                    width: calc(33.33% - 1rem);
                                    border: 0;
                                    outline: none;
                                    margin: 0 1rem 1rem 0;
                                    background-color: #FFF;
                                    border: 1px solid $input-outline;
                                    font-size: 1rem;
                                    color: #313132;
                                    font-weight: 400;
                                    padding: 0 !important;
                                    height: 44px;
                                    &:nth-child(3), &:nth-child(6) {
                                        margin-right: 0;
                                    }
                                    @media only screen and (max-width: 1024px) {
                                        width: calc(50% - 1rem);
                                        &:nth-child(3), &:nth-child(6) {
                                            margin-right: 1rem;
                                        }
                                        
                                    }
                                    &.active {
                                        background-color: $vocih-accent !important;
                                        font-weight: 600;
                                        color: #FFFFFF;
                                        border: 1px solid $vocih-accent;
                                        &:hover {
                                            color: #FFFFFF;
                                            background-color: $vocih-accent !important;
                                            border: 1px solid $vocih-accent;
                                        }
                                    }
                                    &:hover {
                                        background-color: rgba($color: $vocih-accent, $alpha: .08) !important;
                                        border: 2px solid $vocih-accent;
                                        font-weight: 600;
                                        color: $vocih-accent;
                                        border-color: $vocih-accent;
                                  
                                    }
                                }
                            }
                
                        .other{
                            margin-top: 0.5rem;
                            label.other-label { display: block; }
                            .textfield {
                                .dollar {
                                    width: 40px;
                                    height: 40px;
                                    background-color: $vocih-primary;
                                    border: 1px solid $vocih-primary;
                                    text-align: center;
                                    color: #FFFFFF;
                                }
                                .input {
                                    width: calc(100% - 40px);
                                    
                                    input {
                                        height: 40px;
                                        width: 100%;
                                       
                                        &:focus { 
                                           
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .donation-pref-options {
                        margin-top: 3rem;
                    }
                
                    .cover-processing-fee {
                        margin-top: 24px;
                        p.pref-label {
                            margin-bottom: 0px !important;
                            font-weight: 300 !important;
                        }
                        .error-wrap {
                            margin-bottom: 8px;
                            span.error {
                                font-size: 12px !important;
                                line-height: 12px !important;
                                color: #DA1818;
                                }
                        }
                        .cover-button-options {
                            margin-bottom: 2.5rem;
                            @media only screen and (max-width: 1024px) {
                                flex-direction: column !important;
                            }
                            .switch {
                                width: calc( 50% - 8px);
                                @media only screen and (max-width: 1024px) {
                                    width: 100%; 
                                    &:first-child {
                                        margin-bottom: 16px;
                                    }
                                }
                                &.process-yes {}
                                &.process-no {}
                            }
                        }
                    }
                    .org-donation {
                        margin-bottom: 48px;
                        p.pref-label {
                            margin-bottom: 0px !important;
                            font-weight: 300 !important;
                        }
                        .error-wrap {
                            margin-bottom: 8px;
                            span.error {
                                font-size: 12px !important;
                                line-height: 12px !important;
                                color: #DA1818;
                                }
                        }
                        .org-donation-button{}
                        .org-button-options {
                          margin-bottom: 1rem;
                          @media only screen and (max-width: 1024px) {
                            flex-direction: column !important;
                        }
                          .switch {
                            width: calc( 50% - 8px);
                            @media only screen and (max-width: 1024px) {
                                width: 100%; 
                                &:first-child {
                                    margin-bottom: 16px;
                                }
                            }
                              &.org-btn-no {}
                              &.org-btn-yes {}
                              
                          }
                        }
                        .org-name {
                            input {
                                width: 100%;
                            }
                        }
                    }
                
                 
                
                
                }

                .donation-billing-wrapper {
    
                    .billing-tab-title {
                        
                        h5.tab-title {
                            font-size: 24px;
                            margin-bottom: 2rem;
                            font-weight: 600;
                        }
                    }
                
                    .billing-form-content {

                        .form-row {
                            .firstname {
                                @media only screen and (max-width: 1024px) {
                                    margin-bottom: 12px !important;
                                }
                            }
                            .email {
                                @media only screen and (max-width: 1024px) {
                                    margin-bottom: 12px !important;
                                }
                            }
                            .country {
                                @media only screen and (max-width: 1024px) {
                                    margin-bottom: 12px !important;
                                }
                            }
                            
                            .form-col, .form-col-full {
                                label {
                                    margin: 0 !important;
                                    padding: 0 !important;
                                }
                                .label-error {
                                    margin-bottom: 8px;
                                    span.error {
                                        font-size: 12px !important;
                                        line-height: 12px !important;
                                        color: #DA1818;
                                        }
                                }
                                    
                                
                                
                                
                                input.address1 {
                                    margin-bottom: 1rem;
                                }
                              
                            }
                        }
                    }
                
                
                  
                }
    
            }
            
    
            
        }


        .summary-section {
            box-sizing: border-box;
            width: 362px;
            @media only screen and (max-width: 1024px) {
                width: 100% !important;
               margin-top: 48px !important;
            }
            .summary-title {
                padding-bottom: 1.5rem;
                h5 {
                    font-size: 22px;
                    margin-bottom: 1rem;
                    font-weight: 600;
                    }
            }

            .summary-section-wrap {
                width: 100%;
                padding: 24px;
                background-color: #F7F6F8;

                .donation-category-wrap {
                    margin-bottom: 24px;
                  
                    .inner {
                        border-bottom: 1px solid #DADADA;
                        padding-bottom: 24px;
                        .pre-category {
                            padding-bottom: 4px;
                            p {
                                color: #626164;
                                font-weight: 300;
                                font-size: 13px;
                            }
                        }
                        .category-text {
                            padding-bottom: 8px;
                            h3 {
                                font-weight: 400;
                                margin-bottom: 0 !important;
                                font-size: 22px;
                            }
                        }
                        .sumfreq {
                            background-color: #EAEAEA;
                            width: 83px;
                            height: 24px;
                            padding: 6px 10px;
                            border-radius: 3px;
                                font-size: 11px !important;
                                color: #9D9D9D !important;
                                line-height: 12px !important;
                                text-transform: uppercase;
                                letter-spacing: 1px;
                            
                            
                        }
                    }
                }
                .summary-box {
                   
                    .donation-summary {
                        .donation-subtotal {
                            padding: 0 0 1.125rem 0;
                            border-bottom: 1px solid #DADADA;
                            .frequency-subtotal{
                                padding-bottom: 1rem;
                                span.freq-caption {
                                    color: #6C6C6C;
                                    font-size: 14px !important;
                                    font-weight: 400;
                                }
    
                                span.sub-figure {
                                    font-size: 14px !important;
                                    color: #27303A;
                                    font-weight: 600;
                                    text-align: right;
                                }
                            }
                            .description {
                                span {
                                    color: #27303A;
                                    font-family: $font;
                                    font-size: 13px !important; 
                                    font-weight: 300;
                                    
                                }
                            }
                        }
                        .donation-process-fee {
                            border-bottom: 1px solid #DADADA;
                            padding: 1.125rem 0;
                            .processing {
                                padding-bottom: 1rem;
                                .processing-caption {
                                    color: #6C6C6C;
                                    font-size: 14px !important;
                                    font-weight: 400;
                                }
                                .sub-figure {
                                    font-size: 14px !important;
                                    color: #27303A;
                                    font-weight: 600;
                                    text-align: right;
                                }
                                
                            }
                            .processing-checkbox {
                                input {
                                    margin-right: 12px;
                                }
                                span.opt-text {
                                    color: #606468 !important;
                                    font-family: $font;
                                    font-size: 13px !important; 
                                }
                            }
                        }
    
                        .grand-total {
                            border-bottom: 1px solid #DADADA;
                            padding: 1.125rem 0;
                            margin-bottom: 1.25rem;
                            .total-caption {
                                span {
                                    color: #6C6C6C;
                                    font-size: 15px !important;
                                    font-weight: 400;
                                }
                            }
    
                            .total-figure {
                                span.currency {
                                    font-size: 12px !important;
                                    font-weight: 600;
                                    font-family: $font;
                                    margin-right: 0.5rem;
                                }
                                span.total-amt {
                                    font-size: 1.5rem !important;
                                    color: #27303A;
                                    font-weight: 600;
                                    text-align: right;
                                }
                            }
    
                        }
    
    
                        
                    }
                    .continue-btn {
                        .continue-error {
                            font-size: 12px !important;
                            line-height: 12px !important;
                            color: #DA1818;
                            padding-bottom: 12px;
                        }
                        button {
                            width: 100%;
                            background-color: #131117 !important;
                            border-radius: 3px;
                            &:hover {
                                background-color: #242424 !important;
                            }
                        }
                    }
                    
                }
    
            }
            
    
        }

    }
   
}


