section#home-header{
    width: 100%;
    padding: 0;
    padding-top: $spc-4;
    height: 900px;
    background-color: none;
    background-attachment: fixed;
    background-image: url('../../images/header-bg-image.png');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    .tint-overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgb(12,12,13);
        
        background: linear-gradient(45deg, #04040485 0%, #04040449 10%), linear-gradient(0deg, #04040473 0%, #04040449 10%), linear-gradient(-90deg, #0404046c 0%, #04040449 10%), linear-gradient(90deg, #04040493 0%, #04040432 10%),;
    }
        .header-wrap{
            width: 100%;
            height: 100%;
            position: relative;

            .logo-nav {
                position: absolute;
                top: 0;
                left: 0;
                width: 100% !important;
            }
            .header-content {
                    
                    height: 100%;
                    width: 100%;
                    padding-bottom: $spc-16;
                    box-sizing: border-box;
                    .header-inner-content {
                        
                        width: 569px;
                        .title {
                        margin-bottom: 1.5rem;
                        h1 {
                            color: #FFF;
                            font-size: 3rem;
                            line-height: 4.5rem;
                            text-align: left;
                            font-weight: 600;
                            @media only screen and (max-width: 475px) {
                                font-size: 2.5rem !important;
                                line-height: 4rem !important;
                                }
                            }
                        }
                        .copy {
                            margin-bottom: 3rem;
                            width: 100%;
                            p {
                                font-size: 1.25rem;
                                line-height: 2.375rem;
                                font-weight: 300 !important;
                                text-align: left;
                                color: #a7a7a7;
                            }

                        }
                        .learn-more-btn {
                            width: 197px;
                            a {
                                width: 197px;
                                font-size: 1.25rem;
                                padding: 21px 24px !important;
                                .icon {
                                    
                                    animation-name: learnmoreArrow;
                                    animation-duration: .8s;
                                    animation-timing-function: ease;
                                    animation-iteration-count: infinite;
                                    transition: all ease-in .5s;
                                    
                                }
                            }
                        }
                    }
                   
            }
        }
                        
                            
                        
                 
            

}
   

