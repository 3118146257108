section#mobile-menu {
    background-color: #FFF;
    padding: 20px 20px 40px 20px;
    box-sizing: border-box;
    height:700px;
    width: 100%;
    z-index: 999;
    position: fixed;
    top: 0;
    left: 0;
    transform: translateY(-1000px);
    transition: all ease 0.8s;
    &.active {
    transform: translateY(0);
    transform: translateY(0px);
    }
    .mobile-menu-wrap {
        height: 100%;
        position: relative;
        padding-top: 22px;
        .top-bar {
            width: 100%;
            .close-icon {
                padding: 0.5rem;
                cursor: pointer;
            }
    
            .menu-caption {
                width: 78px;
                .menu-text {
                    font-size: 14px;
                    font-family: $font;
                    letter-spacing: 2px;
                }
            }
        }
        .nav-wrap {
            width: 100%;
            height: 100%;
              .links-wrap {
                width: 100%;
                overflow: hidden;
                -webkit-overflow-scrolling: touch;
                .menu-links {
                    padding-top: 0rem;
                    ul {
                        margin: 0;
                        li {
                            list-style: none;
                            padding: 0;
                            
                            a {
                                font-size: 3rem !important;
                                line-height: 6rem;
                                color: #232A33;
                                font-family: $font;
                                font-weight: 500;
                                &.activeMenuLink {
                                    color: $vocih-accent;
                                }
                            }
                        }
                        &:first-child {
                            
                        }
                    }
                
                }
            
            }
    
            .footer {
                width: 100%;
                position: absolute;
                bottom: 0px;
                border-top: 1px solid #D5DDE9;
                padding-top: 60px;
                padding-bottom: 40px;
                .cta {
                    padding: 0;
                    .title {
                        padding-bottom: 2rem;
                        h6 {
                            font-size: 1.5rem;
                            font-weight: 300;
                            color: $vocih-primary;
                        }
                    }
                    
                    .footenote-btn {
                        width: 240px;
                    }
                }
                .footnote {
                    box-sizing: border-box;
                    .foot-content {
                        width: 145px;
                        text-align: right;
                        .contact {
                            margin-bottom: 3rem;
                            span {
                                font-size: 1rem !important;
                                line-height: 2rem !important;
                            }
                        }
                        .social {
                            width: 100%
                        }
                    }
                    
                }
            }
            
        }
         
    }


    
}


