
html, body {
    overflow-x: hidden !important;
}

.full-wrapper {
    width: 100% !important;
    max-width: 1280px;
    padding:0;
    box-sizing: border-box;
    margin: 0 auto;
}


.boxed-wrapper {
    width: 100%;
    max-width: 1140px !important;
    padding: 0;
    box-sizing: border-box;
    margin: 0 auto;
    
    @media screen and (max-width: 1024px) {
        width: 100%;
        padding: 0 40px;
    }
    @media screen and (max-width: 520px) {
        width: 100%;
        padding: 0 20px;
    }

}

.sm-wrapper {
    width: 100%;
    max-width: 1120px !important;
    padding: 0;
    box-sizing: border-box;
    margin: 0 auto;
}

// diable scroll when mobile menu flies out
.fixed-no-scroll {
    overflow: hidden;
}

.modal-overlay {
    width: 100%;
    height: 100%;
    background-color: rgba($color:$vocih-primary, $alpha: .8);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 888;
}
