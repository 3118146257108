section#page-header{
    padding: 2rem 0 $spc-8 0;
    height: 350px;
    background-image: url('../../images/page-header-1.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    background-color:#2e3238;
    color: #FFFFFF;
    position: relative;
    box-sizing: border-box;
    z-index: 10;
   
    .logo--nav {
        position: relative;
        z-index: 100;
    }

    .title-content {
        width: 100%;
        height: 182px;
        .page-title {
            h1{
                font-size: 2.75rem;
                font-weight: 400;
            }
        }
    }

   
}