

// COLORS
$accent: #8C52FF;
$primary: #040404;

$vocih-primary: $primary;
$vocih-accent: $accent;

$input-outline: #C7C5C9;
$grey-section-bg: #F5F5F5;

// SPACING
$spc-1 : 8px;
$spc-2 : 16px;
$spc-3 : 24px;
$spc-4 : 32px;
$spc-5 : 40px;
$spc-8 : 64px;
$spc-10 : 80px;
$spc-12 : 96px;
$spc-16 : 128px;
$spc-18 : 144px;

