.payment-gateway-logo-wrap {
    .title {
        margin-bottom: 1.5rem;
        h6 {
            font-family: $font;
            font-size: 12px !important;
            color: #9c9c9c;
            margin: 0;
        }
    }
    .powered {
        span {
            font-size: 13px;
        }
    }
    .logos {
        width: 75%;
        margin-bottom: 1rem;
        .logo {
            
        }
    }
}