section#logonav {
    background: none;
    width: 100%;
    height: 48px;
    box-sizing: border-box;
    position: relative;
    z-index: 100;
    .logo {
        width: 180px;
        height: 48px;
        font-size:34px;
        font-weight:700;
        color:#FFF;
        position: relative;
        z-index: 10;
        text-transform: uppercase;
    }
}