.paypal-donation-success {
    width: 100%;
    height: 48px;
    .success-icon {
        width: 48px;
    }
    .success-text {
        width: calc(100% - 48px);
        padding-left: 8px;
    }
}

.paypal-processing-error {
    width: 100%;
    height: 48px;
    .error-icon {
        width: 48px;
    }
    .error-text {
        width: calc(100% - 48px);
        padding-left: 8px;
    }
}