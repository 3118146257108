

// Inter font

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;600;700&display=swap');

 
$font : -apple-system,BlinkMacSystemFont,"Inter",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif;



html, * {
    font-family: $font;
    font-size: 16px;
}

h1 {
    font-family: $font;
    font-size: 4rem;
    line-height: 5.4rem;
    font-weight: 600;
    margin: 0;
}

h2 {
    font-family: $font;
    font-size: 3rem;
    line-height: 3.2rem;
    font-weight: 600;
    margin: 0;
}

h5 {
    font-family: $font;
    font-size: 2rem;
    line-height: 3rem;
    font-weight: 600;
    margin: 0;
}

h6 {
    font-family: $font;
    font-size: 1.25rem;
    line-height: 2rem;
    font-weight: 600;
    margin: 0;
}


p {
    font-family: $font;
    margin: 0 !important;
}


.highlight-color-primary {
    color: $vocih-accent;
}


h6.overline {
    font-family: $font;
    text-transform: uppercase;
    color: $vocih-accent;
    letter-spacing: 2px;
    font-weight: 500;
    text-align: center;
   
}

span {
    font-size: inherit !important;
    line-height: inherit !important;
}

a {
    font-family: $font;
    &.link {
        color: $vocih-accent !important;
        text-decoration: none;
        font-family: $font;
        font-weight: 600;
    }
    
}

.capitalize {
    text-transform: capitalize;
}

b {
    font-weight: 600 !important;
}