.mobile-nav {
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 40px;
    @media screen and (max-width: 510px) {
    }
    button {
        background: none;
        border: none;
        width: 48px;
        height: 48px;
        padding: 0 !important;
        .menu-hamburger {
            width: 48px;
            height: 30px;
            .bar {
                width:100%;
                height: 2px;
                position: relative;
                transition: all ease .4s;
                &:first-child { }
                &:nth-child(2) {
                    top: 10px;
                }
                &:last-child { top: 20px;}

            }
        }
    }

    &.active {
        button {
            .menu-hamburger {
                .bar {
                    &:first-child {
                        opacity: 0;
                        transform: translateX(100px);
                       
                    }
                    &:nth-child(2) {
                        margin-left: 0;
                        transform: rotate(45deg);
                    }
                    &:last-child {
                        top: 10px;
                        transform: rotate(-45deg);
                    }
                }
            }
        }
    }
    
}