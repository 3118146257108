section#contact-block {
    padding: $spc-18 0;
    @media only screen and (max-width: 1024px) {
        padding: 124px 0 !important;       
       }
    @media only screen and (max-width: 520px) {
        padding: 80px 0 !important;    
    }
    .boxed-wrapper {
        width: 100%;
        .content{
            width: 100%;
            @media only screen and (max-width: 1024px) {
                flex-direction: column !important;       
               }
            .left-content,.right-content {
                width: 50%;
                @media only screen and (max-width: 1024px) {
                   width: 100% !important;       
                   }
            }

            .left-content {
                @media only screen and (max-width: 1024px) {
                    margin-bottom: 3rem !important;       
                   }
                .title {
                    margin-bottom: 2.5rem;
                    h3 {
                        padding-bottom: 1rem;
                        color: #FFF;
                         font-size: 2.75rem;
                         font-weight: 500;
                         color: $vocih-primary;
                    }
                    p { }
                }

                .email {
                    margin-bottom: 2.5rem;
                    h6 {
                        font-weight: 700;
                    }
                    p {}
                }
                
            }

            .right-content {

                .success-message-div {
                    padding: 12px;
                    margin-bottom: 2rem;
                    flex-basis: calc(100% - 140px);
                    box-sizing: border-box;
                    background-color: #1becb813;
                    border: 1px solid #07977359;
                    border-radius: 3px;
                    @media only screen and (max-width: 700px) {
                    flex-basis: 100% !important;
                    }
                    .status-icon {
                        margin-right: 12px;
                    }
                    .status-message {
                        color: #69776f !important;
                        font-size: 16px;
                    }
                }

                .server-error {
                    background-color: #FEFCFC;
                    border: 1px solid #E79292;
                    margin-bottom: 2.5rem;
                    .error-icon {
                        background-color: #D84A4A;
                        width: 48px;
                        height: 40px;
                        
                    }
                    .error-content {
                        flex-basis: calc(100% - 48px);
                        padding-left: 16px;
                        box-sizing: border-box;
                        color: #BD1B1B;
                        font-weight: 300;
                    }
                }
                
                .label-wrap {
                    label { display: block;}
                    .error {
                        color: #D84A4A;
                        font-size: 12px;
                    }
                }
                
                .form-row {
                    width: 100%;
                    .firstname, .lastname {
                        width: calc(50% - 8px);
                        @media only screen and (max-width: 700px) {
                            width: 100% !important;
                        }
                        .firstname {
                            @media only screen and (max-width: 700px) {
                                margin-bottom: 12px !important;
                            }
                        }
                        input {
                            width: 100%;
                        }
                    }
                    .email {
                        width: 100%;
                        input {
                            width: 100%;

                        }
                    }
                    .message {
                        width: 100%;
                        textarea {
                            padding-left:8px;
                            padding-right: 8px;
                            width: 100% !important;
                        }
                    }
                   &.submit-row {
                       button {
                           width: 200px;
                       }
                    }
                   
                   
                }

                .hp-row {
                    visibility: hidden;
                    input {
                        height: 24px !important;
                    }
                }
            }
        }
    }
}