@keyframes map_pins {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.2);
    }
    100% {
        transform: scale(1);
    }
}

@keyframes learnmoreArrow {
    0% {
        transform: translateY(-4px);
    }
    50% {
        transform: translateY(4px);
    }
    100% {
        transform: translateY(-4px);
    }
  
  
    
    
}